/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { WebViewerWrapper, PDFManagerFactory } from 'pdftron';
import AnnotationMixin from 'pdftron/docManager/Annotation';
import store, { requestsStore } from 'store/store';
import {
  AnnotationCustomData,
  Difference,
  DocumentTypes,
  GraphicZoneCustomData,
  InputAnnotation,
  InspectionStatuses,
  Match,
  PDFTronTools,
  SubDifferenceCustomData,
} from 'types';
import {
  getNextGraphicZoneId,
  getSelectedTool,
  inspection,
  getFocusedDifference,
  getAutoMatchGraphic,
  getShowLiveText,
  getPagesImagesPath,
  getFlashMode,
  app,
  getShowDifferenceBoxes,
} from 'store';
import { deleteDifference, updateDifference } from 'store/request/differences/actions';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import ToolsMixin from '../Tools';
import { shakeDocumentsZones } from './AnnotationTools';
import drawMarkupLabel from './utils/drawMarkupLabel';
import GVAnnotationMixin from './utils/GVAnnotationMixin';
import { PREP_TOOL_LABEL, PREP_TOOL_ANNOTATION } from './utils/annotationConstants';
import DifferenceClass from '../Difference';
import getPositionOfAnnotationRelativeToDocument from './utils/getAnnotationPositionRelativeToDocument';
import { fetchMatchInspection, startMatching } from '../../../store/request/match/actions';
import { CtfMatchLocation, CtfMatchResult } from '../../../store/request/match/types';

export interface SerializedGraphicAnnotation {
  pageNumber: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  shifted: boolean;
  fullPage?: boolean;
  scaled: boolean;
  matchedGraphic: boolean;
  autoGraphic: boolean;
  rotationIndex?: number;
}

declare global {
  interface Window {
    graphicAnimationInterval: any;
    currentShownDocument: DocumentTypes;
    requestInterval: (fn: any, delay: number) => void;
    clearRequestInterval: (handle: { value?: any }) => void;
  }
}

class GraphicZone {
  // This will be used to track the last selected graphic zone.
  static animatedZone = '';

  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  // currently, whether the file dimensions are equal is only relevant to graphic zones, but if this changes this could be moved to the store
  areFileDimensionsEqual = false;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;

    window.requestInterval = function (fn, delay) {
      let start = new Date().getTime();
      const handle: { value?: any } = {};

      function loop() {
        const current = new Date().getTime();
        const delta = current - start;

        if (delta >= delay) {
          fn.call();
          start = new Date().getTime();
        }

        handle.value = requestAnimationFrame(loop);
      }

      handle.value = requestAnimationFrame(loop);
      return handle;
    };

    window.clearRequestInterval = function (handle: { value?: any }) {
      window.cancelAnimationFrame(handle.value);
    };
  }

  get toolsMixin() {
    return new ToolsMixin(this.source, this.target);
  }

  get annotationMixin() {
    return new AnnotationMixin(this.source, this.target);
  }

  createGraphicAnnotation(instance: WebViewerInstance, preloadZoneNumber?: number) {
    class GraphicZoneAnnotation extends GVAnnotationMixin(instance.Core.Annotations.RectangleAnnotation) {
      constructor() {
        super();
        const state = store.getState();
        this.setCustomData(GraphicZoneCustomData.graphicAnnotation, 'true');
        this.setCustomData(GraphicZoneCustomData.zoneNumber, `${preloadZoneNumber || getNextGraphicZoneId(state)}`);
        this.setCustomData(GraphicZoneCustomData.confirmed, 'false');
        this.ToolName = PDFTronTools.GRAPHIC;
        this.IsHoverable = true;
        this.disableRotationControl();
      }

      draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
        this.setStyles(ctx, pageMatrix);
        const showDifferenceBoxes = getShowDifferenceBoxes(store.getState());

        const { DEFAULT_FILL_STYLE, SELECTED_FILL_STYLE } = PREP_TOOL_ANNOTATION;
        // Draw annotation rectangle with custom styling
        const annotationRectangle = new Path2D();
        annotationRectangle.rect(this.X, this.Y, this.Width, this.Height);
        ctx.strokeStyle = showDifferenceBoxes ? SELECTED_FILL_STYLE : 'rgba(255, 255, 255, 0.01)'; // transparent but clickable
        ctx.stroke(annotationRectangle);

        // Check to see if we are creating a graphic diff or zone.
        const state = store.getState();
        if (state.inspection.focusedDifferenceId === '-1') {
          // Draw differently when the annotation is confirmed
          if (this.getCustomData(GraphicZoneCustomData.confirmed) === 'true') {
            ctx.fillStyle = 'rgba(234, 54, 50, 0.23)';
            ctx.fill(annotationRectangle);
          } else {
            // This will allow the user to select unconfirmed graphic zones.
            ctx.fillStyle = 'rgba(255, 255, 255, 0.01)';
            ctx.fill(annotationRectangle);
          }

          const selectedAnnotations = instance.Core.annotationManager.getSelectedAnnotations();
          const isSelected = selectedAnnotations.some((targetAnnot) => targetAnnot.Id.includes(this.Id));
          if (isSelected || (this as any)?.IsHovering) {
            const zoneNumber = this.getCustomData(GraphicZoneCustomData.zoneNumber);
            const labelText = `Graphic Zone: ${zoneNumber}`;
            const { HEIGHT, WIDTH_PADDING, GAP } = PREP_TOOL_LABEL;
            const fillStyle = isSelected ? SELECTED_FILL_STYLE : DEFAULT_FILL_STYLE;
            drawMarkupLabel(ctx, labelText, this.X, this.Y, HEIGHT, GAP, WIDTH_PADDING, fillStyle);
          }

          // For debug purposes
          // ctx.strokeStyle = '#000000';
          // ctx.strokeText(this.Id, this.Width / 2, this.Height / 2);
        }
      }
    }
    return GraphicZoneAnnotation;
  }

  createGraphicZoneAnnotationTools() {
    if (this.source && this.target) {
      [this.source, this.target].forEach((wrapper: WebViewerWrapper) => {
        const { instance } = wrapper;
        const toolObject = new (class GraphicCreateTool extends instance.Core.Tools.RectangleCreateTool {
          constructor(graphicAnnotation: any) {
            super(instance.Core.documentViewer);
            instance.Core.Tools.GenericAnnotationCreateTool.call(this, instance.Core.documentViewer, graphicAnnotation);
          }
        })(this.createGraphicAnnotation(instance));

        wrapper.registerTool({
          toolName: PDFTronTools.GRAPHIC,
          toolObject,
          buttonImage: '',
        });
      });
    }
  }

  getUnconfirmedGraphicZones() {
    return (
      this.source
        ?.getAnnotationList()
        .filter(
          (zone: Core.Annotations.Annotation) =>
            zone.getCustomData(GraphicZoneCustomData.confirmed) !== 'true' &&
            zone.getCustomData(GraphicZoneCustomData.graphicAnnotation) === 'true',
        ) || []
    );
  }

  getUnconfirmedTargetGraphicZones() {
    return (
      this.target
        ?.getAnnotationList()
        .filter(
          (zone: Core.Annotations.Annotation) =>
            zone.getCustomData(GraphicZoneCustomData.confirmed) !== 'true' &&
            zone.getCustomData(GraphicZoneCustomData.graphicAnnotation) === 'true',
        ) || []
    );
  }

  checkUnconfirmedGraphicZones() {
    const srouceUnconfirmed = this.getUnconfirmedGraphicZones();
    const targetUnconfirmed = this.getUnconfirmedTargetGraphicZones();
    return srouceUnconfirmed.length >= 1 || targetUnconfirmed.length >= 1;
  }

  selectUnconfirmedGraphicZone() {
    const sourceToolMode = this.source?.docViewer.getToolMode();
    const targetToolMode = this.target?.docViewer.getToolMode();

    // don't select during shifted graphics
    if (
      sourceToolMode?.name === PDFTronTools.SHIFTED_GRAPHIC ||
      targetToolMode?.name === PDFTronTools.SHIFTED_GRAPHIC
    ) {
      return;
    }

    if (targetToolMode?.name === PDFTronTools.SCALED_GRAPHIC) {
      const targetShiftedAnnot = this.target?.getAnnotationList(
        (annot: any) => annot.ToolName === PDFTronTools.SCALED_GRAPHIC,
      )[0];

      if (targetShiftedAnnot) {
        this.target?.jumpToAnnotation(targetShiftedAnnot.Id);
        this.target?.annotationManager.selectAnnotation(targetShiftedAnnot);
      }
    } else {
      this.source?.annotationManager.deselectAllAnnotations();
      const sourceUnconfirmed = this.getUnconfirmedGraphicZones()[0];
      if (sourceUnconfirmed) {
        this.source?.jumpToAnnotation(sourceUnconfirmed.Id);
        this.source?.annotationManager.selectAnnotation(sourceUnconfirmed);
      }
      const targetUnconfirmed = this.getUnconfirmedTargetGraphicZones()[0];
      if (targetUnconfirmed) {
        this.target?.jumpToAnnotation(targetUnconfirmed.Id);
        this.target?.annotationManager.selectAnnotation(targetUnconfirmed);
      }
    }
  }

  private deleteGraphicDiffZones(annotationId: string) {
    const annotationToDelete = this.target?.annotationManager.getAnnotationById(annotationId);
    if (annotationToDelete) {
      const parentId = annotationToDelete.getCustomData(SubDifferenceCustomData.subDifferenceParentId);
      this.target?.annotationManager.deselectAllAnnotations();
      store.dispatch(deleteDifference(annotationId, parentId));
    }
  }

  private deleteGraphicZones() {
    const annotationToDelete = this.source?.annotationManager.getSelectedAnnotations()[0];
    const annotationToDeleteTarget = this.target?.annotationManager.getSelectedAnnotations()[0];
    if (annotationToDelete?.getCustomData(GraphicZoneCustomData.graphicAnnotation) === 'true') {
      this.source?.annotationManager.deleteAnnotation(annotationToDelete, { imported: true, force: true });
      if (annotationToDeleteTarget) {
        this.target?.annotationManager.deleteAnnotation(annotationToDeleteTarget, { imported: true, force: true });
      }

      // Updates annotations in redux if they were added in the first place.
      if (
        annotationToDelete.getCustomData(GraphicZoneCustomData.confirmed) === 'true' &&
        annotationToDeleteTarget?.getCustomData(GraphicZoneCustomData.confirmed) === 'true'
      ) {
        this.annotationMixin.updateInputAnnotations(); // as this function is also called on the unconfirmed error, we need to prevent autosave.
      }
      shakeDocumentsZones(annotationToDelete);
    }
  }

  handlingDocumentHighlight() {
    const sourceUnconfirmed = this.getUnconfirmedGraphicZones();
    const targetUnconfirmed = this.getUnconfirmedTargetGraphicZones();
    const selectedTool = getSelectedTool(store.getState());

    if (selectedTool === PDFTronTools.GRAPHIC) {
      // if nothing is confirmed at all (Or both confirmed al-ready)
      if (!this.checkUnconfirmedGraphicZones()) {
        this.target?.removeAnnotationDocumentHighlight();
        this.source?.addDocumentHighlight();
      }

      // if target not confirmed yet
      if (sourceUnconfirmed.length === 0 && targetUnconfirmed.length >= 1) {
        this.source?.removeAnnotationDocumentHighlight();
        this.target?.addDocumentHighlight();
      }
    }
  }

  getSerializedGraphicZones(documentType: DocumentTypes = DocumentTypes.source): SerializedGraphicAnnotation[] {
    // please someone make this nicer
    const annotationManager = this[documentType]?.annotationManager;

    if (annotationManager) {
      const graphicZoneAnnotations = annotationManager
        .getAnnotationsList()
        .filter(
          (annotation) =>
            annotation.getCustomData(GraphicZoneCustomData.graphicAnnotation) &&
            annotation.getCustomData(GraphicZoneCustomData.confirmed),
        );
      return (
        graphicZoneAnnotations?.map((annotation) => {
          const { x1, x2, y1, y2 } = annotation.getRect();
          const pageHeight = this[documentType]?.docViewer.getPageHeight(annotation.PageNumber);
          return {
            pageNumber: annotation.PageNumber,
            pageHeight,
            x1,
            x2,
            y1,
            y2,
            shifted: annotation.getCustomData(GraphicZoneCustomData.shifted) === 'true',
            scaled: annotation.getCustomData(GraphicZoneCustomData.scaled) === 'true',
            autoGraphic: annotation.getCustomData(GraphicZoneCustomData.autoGraphicsAnnotations) === 'true',
            matchedGraphic: annotation.getCustomData(GraphicZoneCustomData.matchedGraphicsAnnotation) === 'true',
            rotationIndex:
              documentType === DocumentTypes.target
                ? parseInt(annotation.getCustomData(GraphicZoneCustomData.rotationIndex) || '0', 10)
                : undefined,
          };
        }) || []
      );
    }
    return [];
  }

  /**
   * update whether source and target file are equal in dimensions and number of pages
   */
  updateAreFileDimensionsEqual() {
    if (this.source && this.target) {
      const sourceTotal = this.source.page.getTotalPages();
      const targetTotal = this.target.page.getTotalPages();
      const sourceTotalSize = this.source.page.getTotalPageSize();
      const targetTotalSize = this.target.page.getTotalPageSize();
      if (sourceTotal !== targetTotal) {
        this.areFileDimensionsEqual = false;
      } else {
        this.areFileDimensionsEqual = sourceTotalSize.every(
          (pageSize, index) =>
            pageSize.height === targetTotalSize[index].height && pageSize.width === targetTotalSize[index].width,
        );
      }
    }
  }

  /**
   * Duplicate graphic zone to selected source/target instance
   * @param zone Annotation to be duplicated
   * @param instance The selected destination for the duplicated annotation to display
   */
  duplicateGraphicZone(zone: Core.Annotations.Annotation, instance: WebViewerInstance, page?: number) {
    const annotationManager = instance.Core.documentViewer.getAnnotationManager();
    // Creating target zone annotation with the same values as in source
    const targetZone = new (this.createGraphicAnnotation(
      instance,
      parseInt(zone.getCustomData(GraphicZoneCustomData.zoneNumber), 10),
    ))();
    targetZone.ReadOnly = true;
    targetZone.setCustomData(GraphicZoneCustomData.confirmed, 'false');
    targetZone.Width = zone.Width;
    targetZone.Height = zone.Height;
    targetZone.Id = zone.Id;
    // if Source and New files don’t have the same dimensions and number of pages, the New graphics zone is created on the top left corner of the first page
    targetZone.X = this.areFileDimensionsEqual ? zone.X : 0;
    targetZone.Y = this.areFileDimensionsEqual ? zone.Y : 0;
    targetZone.PageNumber = page || Math.min(zone.PageNumber, instance.Core.documentViewer.getPageCount());

    annotationManager.addAnnotation(targetZone);
    annotationManager.redrawAnnotation(targetZone);
    annotationManager.selectAnnotation(targetZone);
  }

  /**
   * Duplicate auto-matched graphic zone to target
   * @param zone Annotation to be duplicated
   * @param instance The target instance
   * @param ctfBestMatch The result for finding a graphic zone
   */
  duplicateFoundGraphicZone(
    zone: Core.Annotations.Annotation,
    instance: WebViewerInstance,
    ctfBestMatch: CtfMatchLocation,
  ) {
    const annotationManager = instance.Core.documentViewer.getAnnotationManager();

    // Creating target zone annotation with the same values as in source
    const targetZone = new (this.createGraphicAnnotation(
      instance,
      parseInt(zone.getCustomData(GraphicZoneCustomData.zoneNumber), 10),
    ))();

    targetZone.ReadOnly = true;
    targetZone.setCustomData(GraphicZoneCustomData.confirmed, 'false');

    targetZone.setCustomData(GraphicZoneCustomData.shifted, 'true');
    targetZone.setCustomData(GraphicZoneCustomData.scaled, 'true');
    targetZone.setCustomData(GraphicZoneCustomData.autoGraphicsAnnotations, 'true');
    targetZone.setCustomData(GraphicZoneCustomData.matchedGraphicsAnnotation, 'true');

    if (ctfBestMatch.rotationIndex) {
      targetZone.setCustomData(GraphicZoneCustomData.rotationIndex, ctfBestMatch.rotationIndex.toString());
    }

    const sampleBoundingBox = ctfBestMatch.boundingBox;
    targetZone.Width = sampleBoundingBox.right - sampleBoundingBox.left;
    targetZone.Height = sampleBoundingBox.bottom - sampleBoundingBox.top;
    targetZone.Id = zone.Id;
    targetZone.X = sampleBoundingBox.left;
    targetZone.Y = sampleBoundingBox.top;
    targetZone.PageNumber = ctfBestMatch.pageNumber;

    annotationManager.addAnnotation(targetZone);
    annotationManager.redrawAnnotation(targetZone);
    annotationManager.selectAnnotation(targetZone);
    return targetZone;
  }

  animateLoader(iteration = 0) {
    const targetAnnoManager = this.target?.annotationManager;
    const loader = targetAnnoManager?.getAnnotationById('loader');
    const state = store.getState();
    const showDifferenceBoxes = getShowDifferenceBoxes(state);

    if (loader !== undefined && loader !== null && state.inspection.focusedDifferenceId !== '-1') {
      loader.draw = (ctx: CanvasRenderingContext2D) => {
        const x = loader.X;
        const y = loader.Y;
        const w = loader.Width;
        const h = loader.Height;

        if (x && y && w && h) {
          ctx.globalAlpha = 1;
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';
          ctx.globalCompositeOperation = 'source-over';
          ctx.fillStyle = `rgba(255,255,255, ${showDifferenceBoxes ? 0.9 : 0.01})`;
          ctx.fillRect(x, y, w, h);
          ctx.closePath();

          const minVal = Math.min(h, w);
          let diameter = 70;
          if (diameter >= minVal) {
            diameter = minVal - minVal * 0.09;
          }

          ctx.beginPath();
          ctx.strokeStyle = `rgba(255,0,0,1)`;
          ctx.lineWidth = Math.floor(diameter * 0.06);
          ctx.arc(
            x + w / 2,
            y + h / 2,
            diameter / 2,
            (Math.PI / 16) * iteration,
            Math.PI / 3 + (Math.PI / 16) * iteration,
            false,
          );
          ctx.stroke();
        }
      };
      targetAnnoManager?.redrawAnnotation(loader);

      setTimeout(() => {
        this.animateLoader(iteration + 1);
      }, 10);
    }
  }

  private drawFn(
    ctx: CanvasRenderingContext2D,
    imageBitMap: ImageBitmap | null,
    { x = 0, y = 0, width = 0, height = 0, rotationIndex = 0 },
    hideLabel = false,
  ) {
    const showDifferenceBoxes = getShowDifferenceBoxes(store.getState());

    if (rotationIndex) {
      const centerX = x + width / 2;
      const centerY = y + height / 2;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate((rotationIndex * 90 * Math.PI) / 180);
      ctx.translate(-centerX, -centerY);
    }

    ctx.globalAlpha = 1;
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    ctx.globalCompositeOperation = 'destination-over';

    if (imageBitMap) {
      ctx.drawImage(imageBitMap, x, y, width, height);
    } else {
      ctx.fillStyle = 'rgba(255, 255, 255, 0)';
      ctx.fillRect(x, y, width, height);
    }

    ctx.globalCompositeOperation = 'source-over';
    ctx.strokeStyle = showDifferenceBoxes ? '#cd3434' : 'rgba(255, 255, 255, 0.01)';
    ctx.lineWidth = 1.5;
    ctx.strokeRect(x, y, width, height);

    if (hideLabel) {
      ctx.restore();
      return;
    }

    const label = imageBitMap ? 'Source' : 'New';
    const { width: textWidth } = ctx.measureText(label);

    const labelPadding = 6;
    let labelX = x - 0.75;
    let labelY = y - 16;
    const labelDimensions = {
      width: textWidth + 12,
      height: 16,
    };

    if (labelX < 0) {
      labelX = x + labelPadding;
    }
    if (labelY < 0) {
      labelY = y + labelPadding;
    }

    ctx.save();

    if (rotationIndex === 2 || rotationIndex === 3) {
      const labelCenterX = labelX + labelDimensions.width / 2;
      const labelCenterY = labelY + labelDimensions.height / 2;
      ctx.translate(labelCenterX, labelCenterY);
      ctx.rotate(Math.PI); // Rotate the text by 180 degrees
      ctx.translate(-labelCenterX, -labelCenterY);
      labelY = labelY - labelDimensions.height;
      labelX = labelX + labelDimensions.width;
    }

    // Draw the label background rectangle
    const textRectangle = new Path2D();
    textRectangle.rect(labelX, labelY, labelDimensions.width, labelDimensions.height);
    ctx.fillStyle = '#cd3434';
    ctx.fill(textRectangle);

    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#FFFFFF';
    ctx.fillText(label, labelX + 6, labelY + labelDimensions.height / 2);
    ctx.restore();
  }

  async animateMatch(match: Match) {
    if (!this.target || !this.source || !match) return;
    GraphicZone.animatedZone = match.index.toString(); // Store the number globally.
    this.source?.annotationManager.deselectAllAnnotations();
    this.target?.annotationManager.deselectAllAnnotations();

    const flashMode = getFlashMode(store.getState());
    if (!flashMode) return;

    // Download page image
    const state = store.getState();
    const masterBaseImagePath = getPagesImagesPath('master')(state);
    const pageNumber = match.matchDetails[0].sampleLocation.pageNumber;
    const imagePath = `${masterBaseImagePath}${pageNumber}.png`;
    const sourceImage = await fetch(imagePath, { credentials: 'include' });
    // clearTimeout(loadingTimeout);
    const sourceImageBlob = await sourceImage.blob();

    // Determine the type of flash mode and the image to be used
    const FlashModeCFG: Record<string, any> = {
      ['Match']: async (_: WebViewerWrapper) => {
        const matchDetails = match.matchDetails[0];
        const sourceRight = matchDetails.masterLocation.boundingBox.right || 0;
        const sourceBottom = matchDetails.masterLocation.boundingBox.bottom || 0;
        const sourceLeft = matchDetails.masterLocation.boundingBox.left || 0;
        const sourceTop = matchDetails.masterLocation.boundingBox.top || 0;
        const sourceWidth = sourceRight - sourceLeft;
        const sourceHeight = sourceBottom - sourceTop;

        const knownDPI = 300;
        const scaleFactor = knownDPI / 72;
        const sourceImageBitMap = await createImageBitmap(
          sourceImageBlob,
          sourceLeft * scaleFactor,
          sourceTop * scaleFactor,
          sourceWidth * scaleFactor,
          sourceHeight * scaleFactor,
        );

        const targetRight = matchDetails.sampleLocation.boundingBox.right || 0;
        const targetBottom = matchDetails.sampleLocation.boundingBox.bottom || 0;
        const targetLeft = matchDetails.sampleLocation.boundingBox.left || 0;
        const targetTop = matchDetails.sampleLocation.boundingBox.top || 0;
        const targetWidth = targetRight - targetLeft;
        const targetHeight = targetBottom - targetTop;

        return {
          width: targetWidth,
          height: targetHeight,
          x: targetLeft,
          y: targetTop,
          sourceImageBitMap,
        };
      },
    };

    const { width, height, x, y, sourceImageBitMap } = await FlashModeCFG['Match'](this.target);

    // Create Anotation for animation
    const sourceAnimationAnnotation = new (GVAnnotationMixin(
      this.source.instance.Core.Annotations.RectangleAnnotation,
    ))();
    sourceAnimationAnnotation.ReadOnly = true;
    sourceAnimationAnnotation.Subject = 'GraphicAnnotationAnimated';
    sourceAnimationAnnotation.PageNumber = pageNumber;
    sourceAnimationAnnotation.draw = (ctx: CanvasRenderingContext2D) =>
      this.drawFn(ctx, sourceImageBitMap as ImageBitmap, {
        x,
        y,
        width,
        height,
        rotationIndex: match.matchDetails[0].sampleLocation.rotationIndex,
      });

    // Create Anotation for animation
    const targetAnimationAnnotation = new (GVAnnotationMixin(
      this.source.instance.Core.Annotations.RectangleAnnotation,
    ))();
    targetAnimationAnnotation.ReadOnly = true;
    targetAnimationAnnotation.Subject = 'GraphicAnnotationAnimated';
    targetAnimationAnnotation.PageNumber = pageNumber;
    targetAnimationAnnotation.draw = (ctx: CanvasRenderingContext2D) =>
      this.drawFn(ctx, null, {
        x,
        y,
        width,
        height,
        rotationIndex: match.matchDetails[0].sampleLocation.rotationIndex,
      });

    // Start Flashing animation on target
    targetAnimationAnnotation.Hidden = false;
    sourceAnimationAnnotation.Hidden = true;
    this.target.annotationManager.addAnnotation([sourceAnimationAnnotation, targetAnimationAnnotation]);
    this.target.annotationManager.drawAnnotations({ pageNumber }).then(() => {
      if (GraphicZone.animatedZone !== match.index.toString()) return;

      // animate annotations
      window.graphicAnimationInterval = window.requestInterval(() => {
        window.currentShownDocument = !sourceAnimationAnnotation.Hidden ? DocumentTypes.target : DocumentTypes.source;
        targetAnimationAnnotation.Hidden = !targetAnimationAnnotation.Hidden;
        sourceAnimationAnnotation.Hidden = !sourceAnimationAnnotation.Hidden;
        this.target?.annotationManager.updateAnnotation(targetAnimationAnnotation);
        this.target?.annotationManager.updateAnnotation(sourceAnimationAnnotation);
      }, 500);
    });

    // if live text is enabled when the difference is selected, we must redraw the text highlight
    const showLiveText = getShowLiveText(store.getState());
    if (showLiveText) {
      PDFTronManager.redrawLiveTextHighlights();
    }
  }

  reloadGraphicZones(sourceAnnotationsData: InputAnnotation[], targetAnnotationsData: InputAnnotation[]) {
    const getGraphicAnnotationObject = (
      inputAnnotation: InputAnnotation,
      instance: WebViewerInstance,
      index: number,
    ) => {
      const GraphicAnnotation = this.createGraphicAnnotation(instance, index + 1);
      const annotation = new GraphicAnnotation();
      annotation.PageNumber = inputAnnotation.page;
      annotation.setCustomData(GraphicZoneCustomData.confirmed, 'true');
      annotation.setCustomData(AnnotationCustomData.drawMarkups, 'true');
      annotation.Id = inputAnnotation.annotationId;
      if (inputAnnotation.graphicZone) {
        const { x1, x2, y1, y2 } = inputAnnotation.graphicZone;
        annotation.X = x1;
        annotation.Y = y1;
        annotation.Width = Math.abs(x2 - x1);
        annotation.Height = Math.abs(y2 - y1);
        annotation.ReadOnly = true;
        if (inputAnnotation.graphicZone.shifted) {
          annotation.setCustomData(GraphicZoneCustomData.shifted, 'true');
        } else if (inputAnnotation.graphicZone.scaled) {
          annotation.setCustomData(GraphicZoneCustomData.scaled, 'true');
        } else if (inputAnnotation.graphicZone.autoGraphic) {
          annotation.setCustomData(GraphicZoneCustomData.autoGraphicsAnnotations, 'true');
        } else if (inputAnnotation.graphicZone.matchedGraphic) {
          annotation.setCustomData(GraphicZoneCustomData.matchedGraphicsAnnotation, 'true');
        }

        if (inputAnnotation.graphicZone.rotationIndex) {
          annotation.setCustomData(
            GraphicZoneCustomData.matchedGraphicsAnnotation,
            inputAnnotation.graphicZone.rotationIndex.toString(),
          );
        }
      }
      return annotation;
    };
    sourceAnnotationsData.forEach((inputAnnotation, index) => {
      if (!this.source || !inputAnnotation.graphicZone) return;
      const { instance } = this.source;
      if (instance && !inputAnnotation.graphicZone.fullPage) {
        const annotObject = getGraphicAnnotationObject(inputAnnotation, instance, index);
        this.source.annotationManager.addAnnotation(annotObject);
        this.source.annotationManager.drawAnnotations({ pageNumber: annotObject.PageNumber });
      }
    });
    targetAnnotationsData.forEach((inputAnnotation, index) => {
      if (!this.target || !inputAnnotation.graphicZone) return;
      const { instance } = this.target;
      if (instance && !inputAnnotation.graphicZone.fullPage) {
        const annotObject = getGraphicAnnotationObject(inputAnnotation, instance, index);
        this.target.annotationManager.addAnnotation(annotObject);
        this.target.annotationManager.drawAnnotations({ pageNumber: annotObject.PageNumber });
      }
    });
    store.dispatch(inspection.actions.setGraphicZoneId(sourceAnnotationsData.length + 1));
  }

  loadCanvas(
    instance: WebViewerWrapper,
    annotation: Core.Annotations.Annotation,
    zoom: number,
    documentRotation: number,
    matchRotation: number,
  ): Promise<HTMLCanvasElement> {
    return new Promise((resolve) => {
      // annotation is always using the upright orientation, so we need to correct it to its document rotation before it is rotated
      const unrotatedRect = annotation.getRect();
      unrotatedRect.x1;
      const correctedRender = this.correctRenderRectByRotation(
        instance,
        unrotatedRect,
        annotation.getPageNumber(),
        documentRotation,
      );

      const uprightCorrection = (4 - documentRotation + matchRotation) % 4; // we need to complete the rotation depending on so that the canvas is upright (ex. if 90deg, we need to rotate 270 more so that it is 0)
      const doc = instance.docViewer.getDocument();
      doc.loadCanvas({
        drawComplete: resolve,
        pageNumber: annotation.getPageNumber(),
        zoom,
        renderRect: correctedRender,
        pageRotation: uprightCorrection,
      });
    });
  }

  private correctRenderRectByRotation(
    instance: WebViewerWrapper,
    rect: Core.Math.Rect,
    pageNumber: number,
    documentRotation: number,
  ): { x1: number; y1: number; x2: number; y2: number } {
    const height = instance.docViewer.getPageHeight(pageNumber);
    const width = instance.docViewer.getPageWidth(pageNumber);

    let { x1, y1, x2, y2 } = rect;

    switch (documentRotation) {
      // 90 degrees
      case 1:
        [x1, y1, x2, y2] = [height - y2, x1, height - y1, x2];
        break;
      // 180 degrees
      case 2:
        [x1, y1, x2, y2] = [width - x2, height - y2, width - x1, height - y1];
        break;
      // 270 degrees
      case 3:
        [x1, y1, x2, y2] = [y1, width - x2, y2, width - x1];
        break;
    }

    return { x1, y1, x2, y2 };
  }

  createLoadingAnnotation(difference: Difference): Core.Annotations.RectangleAnnotation | null {
    if (!this.target) return null;
    const showDifferenceBoxes = getShowDifferenceBoxes(store.getState());
    const loader = new (DifferenceClass.createDifferenceAnnotation(this.target.instance, DocumentTypes.target))({
      annotData: {
        transformCoord: difference.transformCoord || false,
        location: {
          rect: difference.target.location.rect,
          pageNumber: difference.target.location.pageNumber,
        },
        text: difference.target.text,
      },
      differenceId: 'loader',
      type: difference.type,
    });

    if (loader === null) return null;
    loader.Id = 'loader';
    loader.draw = (ctx: CanvasRenderingContext2D) => {
      ctx.globalAlpha = 1;
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      ctx.globalCompositeOperation = 'source-over';
      ctx.fillStyle = `rbga(255,255,255, ${showDifferenceBoxes ? 1 : 0.01})`;
      ctx.fillRect(loader.X, loader.Y, loader.Width, loader.Height);
      ctx.closePath();
    };

    return loader;
  }

  resetAnimation() {
    this.target?.annotationManager.deleteAnnotations(
      this.target
        .getAnnotationList()
        .filter(
          (annot: Core.Annotations.Annotation) =>
            annot.Subject === 'GraphicAnnotationAnimated' || annot.Id === 'loader',
        ),
      { imported: false, force: true },
    );
    GraphicZone.animatedZone = '';
    if (window.graphicAnimationInterval) {
      window.clearRequestInterval(window.graphicAnimationInterval);
      window.graphicAnimationInterval = null;
    }
  }

  hasConfirmedGraphicZones() {
    if (!this.source) return false;
    return this.source.annotationManager
      .getAnnotationsList()
      .some(
        (annot) =>
          annot.getCustomData(GraphicZoneCustomData.graphicAnnotation) === 'true' &&
          annot.getCustomData(GraphicZoneCustomData.confirmed) === 'true',
      );
  }

  getSeparator() {
    const renderSeparator = () => {
      const separator = document.createElement('div');
      separator.innerText = '|';
      separator.style.color = '#8892AB';
      separator.style.position = 'relative';
      separator.style.alignItems = 'center';
      separator.style.display = 'block';
      separator.style.justifyContent = 'center';
      separator.style.width = 'fit-content';
      separator.style.padding = '6px';
      return separator;
    };
    return {
      type: 'customElement',
      render: renderSeparator,
    };
  }
  // Related to - VE-2801 - Graphic Zone Selection (To Finish)
  getEditShiftedPopup(instance: WebViewerInstance, DropDownMenuDirection: string) {
    const renderShiftButton = () => {
      const button = document.createElement('button');
      button.classList.add('GraphicsZoneDropdown', 'ActionButton');
      button.style.width = 'fit-content';
      button.style.display = 'block';
      button.style.position = 'relative';
      button.style.justifyContent = 'center';
      button.style.alignItems = 'center';
      button.style.paddingLeft = '6px';
      button.style.paddingRight = '8px';
      button.style.height = '32px';

      const icon = document.createElement('img');
      icon.src = '/icons/shiftedGraphics.svg';
      icon.style.width = '24px';
      icon.style.height = '24px';
      icon.style.display = 'block';
      icon.style.alignItems = 'center';
      icon.style.position = 'relative';
      icon.style.justifyContent = 'center';
      button.appendChild(icon);

      const htmlmenu = document.createElement('div');
      htmlmenu.style.display = 'none';
      htmlmenu.style.position = 'relative';
      htmlmenu.style.backgroundColor = '#21242A';
      htmlmenu.style.width = '130px';
      htmlmenu.style.borderRadius = '4px';
      htmlmenu.style.marginTop = DropDownMenuDirection;
      htmlmenu.style.marginLeft = '-10px';
      htmlmenu.style.flexDirection = 'column';
      htmlmenu.style.padding = '4px';
      htmlmenu.style.zIndex = '1';
      const htmlItems = `
      <style>
      @media screen and (max-width: 1500px) {
        .buttonClass{
          display: inline-block;
          position: relative;
          justify-content: center;
          align-items: center;
          padding: 4px;
          text-transform: capitalize;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 16px;
          border: none;
          background-color: transparent;
          cursor: pointer;
          border-radius: 4px;
          width: auto;
          height: 24px;
          outline: none;
          white-space: nowrap;
        }
      }
      .buttonClass:hover{
        cursor: pointer;
        background-color: rgba(51, 66, 80, 1);
        position: relative;
      }
      .buttonClass:focus {
        background-color: rgba(255, 255, 255, 0.08);
        outline: none;
      }
      </style>`;
      htmlmenu.innerHTML = htmlItems;
      button.appendChild(htmlmenu);

      const ShiftOnly = document.createElement('button');
      ShiftOnly.classList.add('buttonClass');
      ShiftOnly.innerText = 'Shifted Graphics';
      htmlmenu.appendChild(ShiftOnly);

      const ShiftScaled = document.createElement('button');
      ShiftScaled.classList.add('buttonClass');
      ShiftScaled.innerText = 'Scaled Graphics';
      htmlmenu.appendChild(ShiftScaled);

      let isPopUpOpen = false;
      const closePopUp = () => {
        htmlmenu.style.display = 'none';
        isPopUpOpen = false;
      };

      button.addEventListener('click', () => {
        htmlmenu.style.display = 'block';

        if (isPopUpOpen) {
          closePopUp();
          button.classList.remove('open');
        } else {
          htmlmenu.style.display = 'block';
          isPopUpOpen = true;
          button.classList.add('open');
        }
      });

      ShiftOnly?.addEventListener('click', () => {
        const selectedAnnotations = instance.Core.annotationManager.getSelectedAnnotations();
        if (selectedAnnotations.length === 1) {
          // represents the selected target graphic zone
          const selectedGraphicZone = selectedAnnotations[0];
          // set the selected source and target graphic zone Ids as current shiftedGraphicRefId in redux
          store.dispatch(inspection.actions.setShiftedGraphicRefId(selectedGraphicZone.Id));
          this.source?.instance.UI.setToolMode(PDFTronTools.SHIFTED_GRAPHIC);
          closePopUp();
        }
      });

      ShiftScaled?.addEventListener('click', () => {
        const selectedAnnotations = instance.Core.annotationManager.getSelectedAnnotations();
        if (selectedAnnotations.length === 1) {
          // represents the selected target graphic zone
          const selectedGraphicZone = selectedAnnotations[0];
          // set the selected source and target graphic zone Ids as current shiftedGraphicRefId in redux
          store.dispatch(inspection.actions.setShiftedGraphicRefId(selectedGraphicZone.Id));
          this.target?.instance.UI.setToolMode(PDFTronTools.SCALED_GRAPHIC);
          this.target?.addDocumentHighlight();
          closePopUp();
        }
      });

      return button;
    };

    return {
      type: 'customElement',
      render: renderShiftButton,
    };
  }

  graphicDiffAnnotationSelected(id: string, action: string) {
    if (action === 'selected') {
      const pdfDocManager = PDFManagerFactory.getPDFDocManager();
      if (!pdfDocManager) return;
      pdfDocManager.zoomToDifference(id);
      pdfDocManager.scrollToDifferences(id);
      store.dispatch(updateDifference(id, { viewed: true }));
    }
  }

  updateAnnotationZoneSelected(id: string) {
    const pdfDocManager = PDFManagerFactory.getPDFDocManager();
    if (!pdfDocManager) return;

    // draw navigation and label for graphics differences
    const focusedGraphicDiff = getFocusedDifference(store.getState());
    if (!focusedGraphicDiff) return;

    const graphicDiffList = focusedGraphicDiff.subDiff;
    const listSize = graphicDiffList.length;
    const graphicDiffIndex = graphicDiffList.findIndex((diff) => diff.id === id);
    let previousDifferenceId: string | false;
    let nextDifferenceId: string | false;

    if (graphicDiffIndex === -1) {
      // is created difference
      previousDifferenceId = graphicDiffList[listSize - 1]?.id ?? false;
      nextDifferenceId = graphicDiffList[0]?.id ?? false;
    } else if (listSize === 1) {
      previousDifferenceId = false;
      nextDifferenceId = false;
    } else {
      previousDifferenceId = graphicDiffList[(graphicDiffIndex + listSize - 1) % listSize]?.id;
      nextDifferenceId = graphicDiffList[(graphicDiffIndex + listSize + 1) % listSize]?.id;
    }

    const previousDiffPopup = pdfDocManager.getPreviousGraphicDifferencePopup(previousDifferenceId);
    const nextDiffPopup = pdfDocManager.getNextGraphicDifferencePopup(nextDifferenceId);
    const deletePopup = this.getDeleteGraphicDiffPopup(id);
    this.target?.instance.UI.annotationPopup.update([previousDiffPopup, nextDiffPopup, deletePopup]);
  }

  graphicZoneAnnotationSelected(documentType: DocumentTypes, annotation: Core.Annotations.Annotation, _action: string) {
    if (documentType === DocumentTypes.source && this.source) {
      const popupButtons = [this.getDeleteGraphicPopup()];
      if (annotation.getCustomData(GraphicZoneCustomData.confirmed) !== 'true') {
        popupButtons.push(this.getConfirmSearchPopup(this.source.instance, DocumentTypes.source));
      }
      this.source.instance.UI.annotationPopup.update(popupButtons);
      // get the matching source zone by id
      const mirrorAnnotationTarget = this.target?.getAnnotationById(annotation.Id);

      // jump and select matching target annotation if its not already selected by loop
      if (mirrorAnnotationTarget && !this.target?.annotationManager.isAnnotationSelected(mirrorAnnotationTarget)) {
        this.target?.instance.Core.annotationManager.jumpToAnnotation(mirrorAnnotationTarget);
        setTimeout(() => {
          this.target?.instance.Core.annotationManager.selectAnnotation(mirrorAnnotationTarget);
        }, 50);
      }
    } else {
      if (annotation.getCustomData(GraphicZoneCustomData.confirmed) !== 'true') {
        let DropDownMenuDirection = '';

        // PDFTron sometimes throws an error when trying to get the position of newly created annotations. This will
        // now default to a direction if it does.
        try {
          const annotationPosition = getPositionOfAnnotationRelativeToDocument(annotation, DocumentTypes.source);
          if (!annotationPosition) return;

          const viewElement = PDFTronManager.getPDFInstance(
            DocumentTypes.target,
          )?.Core.documentViewer.getScrollViewElement();
          if (!viewElement) return;

          const DROPDOWNMENU_MAX_HEIGHT = 75; // DropdownMenu height.
          const ANNOTATION_POPUP_HEIGHT = 40; // Annotation Popup maxHeight

          const { clientHeight } = viewElement;
          const { y, heightOffset } = annotationPosition;

          const annotationSpaceLeft = clientHeight - (y + heightOffset);
          if (
            annotationSpaceLeft < DROPDOWNMENU_MAX_HEIGHT + ANNOTATION_POPUP_HEIGHT &&
            annotationSpaceLeft > ANNOTATION_POPUP_HEIGHT
          ) {
            DropDownMenuDirection = '-103px';
          }
        } catch {
          DropDownMenuDirection = '8px';
        }

        this.target?.instance.UI.annotationPopup.update([
          this.getEditShiftedPopup(this.target.instance, DropDownMenuDirection),
          this.getSeparator(),
          this.getDeleteGraphicPopup(),
          this.getConfirmGraphicPopup(this.target.instance, DocumentTypes.target),
        ]);
      } else {
        this.target?.instance.UI.annotationPopup.update([]);
      }

      // get the matching source zone by id
      const mirrorAnnotationSource = this.source?.getAnnotationById(annotation.Id);

      // jump and select matching source annotation if its not already selected by loop
      if (mirrorAnnotationSource && !this.source?.annotationManager.isAnnotationSelected(mirrorAnnotationSource)) {
        this.source?.instance.Core.annotationManager.jumpToAnnotation(mirrorAnnotationSource);
        setTimeout(() => {
          this.source?.instance.Core.annotationManager.selectAnnotation(mirrorAnnotationSource);
        }, 50);
      }
    }
  }

  // called from saga
  graphicZoneAnnotationChanged(documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) {
    if (action === 'add') {
      if (documentType === DocumentTypes.source) {
        this.source?.annotationManager.selectAnnotation(annotation);
        // deselect all target annotations so we don't delete them afterwards.
        this.target?.annotationManager.deselectAllAnnotations();
        const unconfirmedGraphicZones = this.getUnconfirmedGraphicZones();
        const targetUnconfirmedZones = this.getUnconfirmedTargetGraphicZones();
        if (unconfirmedGraphicZones.length > 1 || targetUnconfirmedZones.length > 0) {
          store.dispatch(inspection.actions.setUnconfirmGraphicZoneError(true));
          this.deleteGraphicZones();
          this.source?.instance.Core.annotationManager.deleteAnnotation(annotation);
          this.selectUnconfirmedGraphicZone();
        }
      } else {
        this.target?.annotationManager.jumpToAnnotation(annotation);
      }

      this.source?.instance.Core.annotationManager?.selectAnnotation(annotation);
    }
  }

  getDeleteGraphicDiffPopup(annotationId: string) {
    return {
      type: 'actionButton',
      img: '/icons/zoneDelete.svg',
      title: 'Delete',
      onClick: () => {
        this.deleteGraphicDiffZones(annotationId);
      },
    };
  }

  getDeleteGraphicPopup() {
    return {
      type: 'actionButton',
      img: '/icons/zoneDelete.svg',
      title: 'Delete',
      onClick: () => {
        this.deleteGraphicZones();
        this.handlingDocumentHighlight();
      },
    };
  }

  findBox(ar: any[]) {
    const start = ar[0].pt;

    let minX = start.x;
    let maxX = start.x;
    let minY = start.y;
    let maxY = start.y;

    for (let i = 1; i < ar.length; i++) {
      const check = ar[i].pt;

      minX = Math.min(minX, check.x);
      maxX = Math.max(maxX, check.x);
      minY = Math.min(minY, check.y);
      maxY = Math.max(maxY, check.y);
    }

    return {
      x1: minX,
      y1: minY,
      x2: maxX,
      y2: maxY,
      w: maxX - minX,
      h: maxY - minY,
    };
  }

  // Related to - VE-2793 - Confirm button - New Panel menu - (To Edit)
  getConfirmGraphicPopup(instance: WebViewerInstance, documentType: DocumentTypes) {
    return {
      type: 'actionButton',
      img: '/icons/check-icon.svg',
      title: 'Confirm',
      onClick: () => {
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];
        const sourceAnnoManager = this.source?.annotationManager;
        const targetAnnoManager = this.target?.annotationManager;
        if (documentType === DocumentTypes.source) {
          sourceAnnoManager?.deselectAnnotation(selectedAnnotation);
          if (this.target) {
            this.duplicateGraphicZone(selectedAnnotation, this.target.instance);
          }
        } else {
          store.dispatch(inspection.actions.increaseGraphicZoneId());
          this.annotationMixin.updateInputAnnotations();
          targetAnnoManager?.deselectAnnotation(selectedAnnotation);
        }
        selectedAnnotation.setCustomData(GraphicZoneCustomData.confirmed, 'true');
        selectedAnnotation.ReadOnly = true;
        this.handlingDocumentHighlight();
      },
    };
  }

  static distance = (a: any, t: any) => Math.abs(t - a);

  // Related to - VE-2793 - Confirm button - New Panel menu - (To Edit)
  getConfirmSearchPopup(instance: WebViewerInstance, documentType: DocumentTypes) {
    return {
      type: 'actionButton',
      img: '/icons/check-icon.svg',
      title: 'Confirm',
      onClick: async () => {
        if (!this.source || !this.target) return;

        // Lock UI while searching
        store.dispatch(inspection.actions.setSearchingForZone(true));
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];

        const sourceAnnoManager = this.source?.annotationManager;
        const targetAnnoManager = this.target?.annotationManager;

        selectedAnnotation.NoResize = true;
        selectedAnnotation.NoMove = true;
        sourceAnnoManager?.updateAnnotation(selectedAnnotation);

        let state = store.getState();
        const autoMatchGraphic = getAutoMatchGraphic(state);

        if (documentType === DocumentTypes.source) {
          if (this.target && this.source) {
            try {
              // USING CTF MATCHING
              const zoneRect = selectedAnnotation.getRect();
              const crop = {
                boundingBox: { top: zoneRect.y1, left: zoneRect.x1, bottom: zoneRect.y2, right: zoneRect.x2 },
                pageNumber: selectedAnnotation.PageNumber,
              };

              let bestMatch: CtfMatchLocation | undefined;
              try {
                const response = await requestsStore.dispatchRequest(
                  startMatching(state.inspection.inspectionId, crop),
                );
                if (!response.data) {
                  throw new Error('error in start matching');
                }
                const matchId = response.data.id;

                bestMatch = await new Promise<CtfMatchLocation | undefined>((res) => {
                  const intervalId = window.setInterval(() => {
                    try {
                      const stateUpdate = store.getState();
                      if (!stateUpdate.inspection.searchingForGraphicZone) {
                        window.clearInterval(intervalId);
                        throw new Error('cancelled');
                      }
                      requestsStore
                        .dispatchRequest(fetchMatchInspection(matchId))
                        .then((response) => {
                          if (response.error) {
                            throw new Error('error in start matching');
                          }
                          if (response.data?.status === InspectionStatuses.error) {
                            throw new Error('error in matching');
                          }
                          if (response.data?.status === InspectionStatuses.completed) {
                            window.clearInterval(intervalId);

                            const bestMatch = response.data.bestMatch || undefined;
                            // eslint-disable-next-line
                            res(bestMatch);
                          }
                        })
                        .catch((_err) => {
                          window.clearInterval(intervalId);
                          store.dispatch(inspection.actions.setSearchingForZone(false));
                        });
                    } catch {
                      window.clearInterval(intervalId);
                    }
                  }, 1000);
                });
              } catch {
                store.dispatch(inspection.actions.setSearchingForZone(false));
                return;
              }

              // we need to deselect the annotation so that when it is reselected, it is updated with the correct UI
              sourceAnnoManager?.deselectAnnotation(selectedAnnotation);
              if (bestMatch) {
                // case #1: ctf graphics gives successful match
                // graphics matches are ordered from best match to worst
                const duplicateAnnotation = this.duplicateFoundGraphicZone(
                  selectedAnnotation,
                  this.target.instance,
                  bestMatch,
                );
                selectedAnnotation.setCustomData(GraphicZoneCustomData.autoGraphicsAnnotations, 'true');
                store.dispatch(
                  inspection.actions.setAutoMatchGraphic({
                    autoMatchPass: autoMatchGraphic.autoMatchPass ? autoMatchGraphic.autoMatchPass + 1 : 1,
                  }),
                );
                selectedAnnotation.setCustomData(GraphicZoneCustomData.shifted, 'true'); // we set the shifted flag for automatched graphics to disable auto-detection/auto-discord
                duplicateAnnotation.setCustomData(GraphicZoneCustomData.shifted, 'true'); // we set the shifted flag for automatched graphics to disable auto-detection/auto-discord
              } else {
                // no matches found from ctf
                this.duplicateGraphicZone(selectedAnnotation, this.target.instance, 1);
                store.dispatch(
                  inspection.actions.setAutoMatchGraphic({
                    autoMatchFail: autoMatchGraphic.autoMatchFail ? autoMatchGraphic.autoMatchFail + 1 : 1,
                  }),
                );
              }
            } catch {
              // If there is an error, revert back to default behavoir
              this.duplicateGraphicZone(selectedAnnotation, this.target.instance);
              const duplicateAnnotation = this.target.instance.Core.annotationManager.getAnnotationById(
                selectedAnnotation.Id,
              );
              selectedAnnotation.setCustomData(GraphicZoneCustomData.shifted, 'true'); // we set the shifted flag to disable auto-detection for failed zones
              duplicateAnnotation.setCustomData(GraphicZoneCustomData.shifted, 'true'); // we set the shifted flag to disable auto-detection for failed zones
            }
          }
        } else {
          store.dispatch(inspection.actions.increaseGraphicZoneId());
          this.annotationMixin.updateInputAnnotations();
          targetAnnoManager?.deselectAnnotation(selectedAnnotation);
        }
        selectedAnnotation.setCustomData(GraphicZoneCustomData.confirmed, 'true');
        selectedAnnotation.ReadOnly = true;
        this.handlingDocumentHighlight();

        store.dispatch(inspection.actions.setSearchingForZone(false));
      },
    };
  }

  containsGraphicAnnotation() {
    return (
      this.source?.annotationManager.getAnnotationsList().find((value: { ToolName: string }) => {
        return value.ToolName === 'GraphicCreateTool';
      }) ||
      this.target?.annotationManager.getAnnotationsList().find((value: { ToolName: string }) => {
        return value.ToolName === 'GraphicCreateTool';
      })
    );
  }

  GraphicDifferenceFocused(differences: Difference[]): void {
    if (getFlashMode(store.getState()) && differences[0].matchIndex !== undefined) {
      store.dispatch(app.actions.setFocusedMatchIndex(differences[0].matchIndex)); // Triggers GraphicMatchFocused for the associated Match
    }
  }

  GraphicMatchFocused(match: Match | CtfMatchResult): void {
    const state = store.getState();
    if (getFlashMode(state)) {
      this.resetAnimation();
      this.animateMatch(match);
    }
  }

  getPreviousGraphicDifferencePopup(diff: string | false) {
    const annotManager = PDFManagerFactory.getViewer(DocumentTypes.target)?.annotationManager;
    const renderPreviousGraphicDiffPopup = () => {
      const button = document.createElement('button');
      button.classList.add('Button', 'ActionButton');

      const icon = document.createElement('img');
      icon.src = '/icons/leftChevron.svg';
      button.appendChild(icon);

      if (diff) {
        button.addEventListener('click', () => {
          const annotation = annotManager?.getAnnotationById(diff);
          if (annotation) {
            annotManager?.deselectAllAnnotations();
            annotManager?.selectAnnotation(annotation);
          }
        });
      } else {
        // button is disabled
        button.style.opacity = '0.5';
        button.style.pointerEvents = 'none';
      }

      return button;
    };
    return {
      type: 'customElement',
      title: 'Previous Difference',
      render: renderPreviousGraphicDiffPopup,
    };
  }

  getNextGraphicDifferencePopup(diff: string | false) {
    const annotManager = PDFManagerFactory.getViewer(DocumentTypes.target)?.annotationManager;
    const renderNextGraphicDiffPopup = () => {
      const button = document.createElement('button');
      button.classList.add('Button', 'ActionButton');

      const icon = document.createElement('img');
      icon.src = '/icons/rightChevron.svg';
      button.appendChild(icon);

      if (diff) {
        button.addEventListener('click', () => {
          const annotation = annotManager?.getAnnotationById(diff);
          if (annotation) {
            annotManager?.deselectAllAnnotations();
            annotManager?.selectAnnotation(annotation);
          }
        });
      } else {
        // button is disabled
        button.style.opacity = '0.5';
        button.style.pointerEvents = 'none';
      }

      return button;
    };
    return {
      type: 'customElement',
      title: 'Next Difference',
      render: renderNextGraphicDiffPopup,
    };
  }
}

export default GraphicZone;
